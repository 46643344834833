import React, {useState} from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo"
import InnovationJSON from "../content/innovations.json"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { faBell, faCalendarAlt, faChartBar } from '@fortawesome/free-regular-svg-icons'
import { Container, Row, Col } from 'react-bootstrap'
import RipplegoIcons from "../components/ripplegoIcons"
import {Link } from "gatsby"
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";

export default function RippleGoThanks() {
    const content = InnovationJSON.innovations[3]
    const [type, setType] = useState("")
    const [hoverType, setHoverType] = useState("")
    
    const popup = name => {
        switch(name) {
            case 'BRIDGE':
                return (
                    <div className={'ripplego-popup-inner rounded-left ' + type}>
                        <div className="border-bottom"><b>KANAWHA AND MICHIGAN RAILROAD BRIDGE</b></div>
                        <p><b>ETA: </b>December 25, 2021 15:25</p>
                        <RipplegoIcons type="BOAT1"/>
                    </div>
                )
            case 'RIVER':
                return (
                    <div className={'ripplego-popup-inner rounded-left ' + type}>
                        <div className="border-bottom"><b>OHIO RIVER AT POINT PLEASANT, WV</b></div>
                        <p><b>ETA: </b>December 30, 2021 07:10</p>
                        <RipplegoIcons type="BOAT2"/>
                    </div>
                )
            case 'LOCK': 
                return (
                    <div className={'ripplego-popup-inner rounded-left ' + type}>
                        <div className="border-bottom"><b>GREENUP LOCKS AND DAM</b></div>
                        <span><b>Current status: </b>No current lock status data</span><br/>
                        <span><b>Mile: </b>Ohio River Mile 341</span><br/>
                        <span><b>ETA: </b>January 05, 2022 10:13</span>
                    </div>
                )
            case 'MSI': 
                return (
                    <div className={'ripplego-popup-inner rounded-left ' + type}>
                        <div className="border-bottom"><b>BNM Notice At Mile Ohio River 310.7</b></div>
                        <span>On the West End Bridge Mi 310.7. OHR (LLNR 25010) The right descending red pier light and ...</span>
                    </div>
                )
            default:
                return null
        }
    }

    const featureIcons = name => {
        switch(name) {
            case "planning":
                return <FontAwesomeIcon icon={faCalendarAlt} className="fa-icons ripplego" />
            case "alert":
                return <FontAwesomeIcon icon={faBell} className="fa-icons ripplego" />
            case "security":
                return <FontAwesomeIcon icon={faShieldAlt} className="fa-icons ripplego" />
            case "predictive":
                return <FontAwesomeIcon icon={faChartBar} className="fa-icons ripplego" />
            default:
                return null
        }
    }

    const selectedType = name => {
        setType(name !== type ? name : "")
    }


    return (
        <Layout>
            <Seo title={content.title}/>
            <img className="parallax ripplego" src="/river-map.jpg" alt="Parallax"/>
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <div>
                    <img src="/ripplego_logo_white.png" alt="RippleGo" style={{width: '85%', minWidth: '300px', margin: '1rem 0 2rem'}}/>
                    <h6 style={{color: "white", marginBottom: "2rem", marginTop: '0rem', fontStyle: 'italic'}}>{content.slogan}</h6>
                </div>
            </div>
            

            <Container fluid className="" style={{boxShadow: 'inset 0 5px 5px  #bebebe', backgroundColor: 'white'}}  id="form">
                <Container fluid className="mt-0 container-padding">
                    <div style={{width: '100%', height: '50px', backgroundColor: 'white'}}/>
                    <Row className="d-flex justify-content-between">
                        <Col lg={6}>
                            <p><span style={{fontStyle: 'italic'}}><b>RippleGo</b></span> is a new software application that
                                uses predictive analytics to provide accurate
                                and timely alerts on river level conditions,
                                navigation hazards, lock status changes and
                                bridge air gap clearance.
                            </p>
                            <p>Each time something changes, <span style={{fontStyle: 'italic'}}><b>RippleGo </b></span>
                                automatically alerts you and recalculates your
                                ETA to destination, so you can get the most
                                out of every voyage.
                            </p>
                            <h4 className="pb-3 pt-5 text-left" >Join us for a free Demo and Luncheon</h4>
                            <p><b>Date:</b> Thursday | December 2nd, 2021</p>
                            <div className="d-flex mb-3"><span className="mr-2"><b>Location:</b> </span><div>Hampton Inn and Suites Hotel<br/>1201 Convention Center Blvd<br/>New Orleans, LA 70130</div></div>
                            <p><b>Room:</b> Dauphine I &amp; II</p>
                            <p><b>Check-in and Luncheon from:</b> 11:45 a.m. – 12:30 p.m.</p>
                            <p><b>Demo and Q&amp;A from:</b> 12:30 p.m. – 1:30 p.m.</p>
                            <p>This event is free to all who sign up. Limit two guests per company.</p>
                        </Col>
                        <Col lg={6} className="text-center py-3 my-auto" >
                            <h5>Thank you! <br/>We'll see you there</h5>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid id="intro" className="px-0 text-white" style={{backgroundColor: '#009090'}}>
                <Container fluid className="my-0 text-center container-padding">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55328.43635178094!2d-90.1114126027257!3d29.921117044954887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8620a66ff3d63175%3A0x77a4ee087282bbaf!2sHampton%20Inn%20%26%20Suites%20New%20Orleans-Convention%20Center!5e0!3m2!1sen!2sus!4v1637182607152!5m2!1sen!2sus" 
                        style={{border: 0, width: "100%", height: '450px', borderRadius: '10px'}}
                        title="Hampton Inn and Suites Hotel Google Map"
                        loading="lazy">
                    </iframe>
                </Container>
            </Container>

            <Container fluid className="px-0 bg-white">
                <Container fluid className="my-0 text-center container-padding">
                    <h5>Why Did We Create RippleGo?</h5>
                    {content.solution.map( (info, index) => (
                        <div key={index} className="text-left">
                            <h5 className={"pb-2 " + (info.header === "Our Solution" ? 'mt-5' : 'mt-2')}>{info.header}</h5>
                            {info.subheader && 
                                <h6 className="mb-3">{info.subheader}</h6>
                            }
                             {info.header === "Our Solution" ? 
                            <p>RippleGo is a <u><a style={{color: 'white'}} href='https://patents.google.com/patent/US20220245456A1/' target='_blank' rel="noopener noreferrer">patented</a></u>, voyage planning tool and alert system that uses predictive analytics to monitor and analyze dozens of data sources (including AIS data from Spire) to forecast changes and provide accurate, real-time notifications on current conditions and forecasted changes along your voyage (including changes in river level conditions, navigation hazards, lock status changes and bridge air gap clearances).</p>
                            :
                                <p>{info.description}</p>
                            }
                        </div>
                    ))}
                    <Link 
                        className="link-button ripplego learn mx-auto mt-4"
                        to="/ripplego"
                    >
                        Request a Demo
                    </Link>

                </Container>
            </Container>

            <Container fluid id="box3" className="px-0 position-relative text-white" style={{backgroundColor: '#009090'}}>
                <Container fluid className="my-0 text-center container-padding">
                    <h5 className="pb-3" >{content.featuresHeader}</h5>
                    <div className="mx-auto mb-5 bg-white" style={{width: '80px', height: '3px'}}/>
                    <Row className="mb-5">
                        {content.features.map( (item, index) => (
                            <Col key={index} lg={3} className="pt-2">
                                {featureIcons(item.shortName)}
                                <h5>{item.header}</h5>
                                <ul className="text-left">
                                    {item.list.map( (li, index) => (
                                        <li key={index}>
                                            {li}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>

            <Container fluid id="box3" className="bg-white px-0 position-relative">
                <Container fluid className="my-0 text-center" id="ripplego_map">
                    <h5 className="pt-3" >{content.alertsHeader}</h5>
                    {type === "" && <span style={{opacity: 0.7, fontStyle: "italic"}}>Click on the icons to interact</span>}
                    <div className="d-flex justify-content-center pt-1">
                        {content.alerts.map( (alert, index) => (
                            <RipplegoIcons key={index} type={alert.type} color={hoverType === alert.type ? alert.darkerColor : alert.color} clicked={type === alert.type ? true : false} onClick={() => selectedType(alert.type)} onMouseEnter={() => setHoverType(alert.type)} onMouseLeave={() => setHoverType("")}/>
                        ))}
                    </div>
                    {type !== "" &&
                        <div className="ripplego-popup">
                            {popup(type)}
                        </div>
                    }
                </Container>
            </Container>
            
            <Container fluid className="px-0" style={{backgroundColor: '#009090'}}>
                <Container fluid className="my-0 text-white container-padding">
                    <h5 className="pb-2 mt-2" >{content.videoHeader}</h5>
                    <div className="embed-responsive embed-responsive-16by9 w-100 mx-auto" style={{borderRadius: 10}}>
                        <iframe 
                            width="1904" 
                            height="775" 
                            src="https://www.youtube.com/embed/ihvlHUVBmwM" 
                            title="Trabus Technologies RippleGo Demo Video" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                </Container>
            </Container>

            <Container fluid id="box4" className="px-0 position-relative" style={{backgroundColor: 'white'}}>
                <div className="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <Container fluid className="my-0 text-center container-padding">
                    <h5 className="pb-3 mt-5 pt-3" >{content.tester.header}</h5>
                    <div className="mx-auto mb-4 bg-white" style={{width: '80px', height: '3px'}}/>
                    <p style={{whiteSpace: "pre-wrap"}}>{content.tester.info}</p>
                    <Link 
                        className="link-button ripplego learn mx-auto mt-4"
                        to="/ripplego"
                    >
                        Become a Beta Tester
                    </Link>
                </Container>
            </Container>
        </Layout>
    )
}
